/* eslint-disable camelcase */
import React from 'react'
import { flags, useFeatureFlags } from '@lib/feature-flags'
import PropTypes from 'prop-types'
import Link from 'next/link'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { formattedTimeWithTimezone } from '@utils/dateUtils'
import ButtonCta from '@components/atoms/button-cta'
import IconFlagFilled from '@components/atoms/icons/icon-flag-filled'
import {
  IconPrice,
} from '@components/atoms/icons'
import { centsToDollarsFormatted } from '@lib/conversions'
import useStyles, { CardIcon } from './program-card.styles'

const JRLLogoIcon = 'https://images.ctfassets.net/56u5qdsjym8c/7sy1Gw4RKBLC9d8ylCdMOs/ec28c360c5b398789a2cb0fd48525519/jr_league.svg'
const FGLogoIcon = 'https://images.ctfassets.net/56u5qdsjym8c/4ECZPKJ1X5na6EW0xH33X3/48e67d481aac9b713c073f712c546516/family-golf-logo__3_.svg'

const ProgramCardAlgolia = ({ event, dataAttrs, openInNewTab }) => {
  const classes = useStyles()
  const {
    title,
    start_date_time,
    end_date_time,
    price_in_cents,
    coach_facility_timezone,
    coach_facility_name,
    tags,
    url,
    additional_fees,
    total_price_in_cents,
  } = event

  const timePeriod = formattedTimeWithTimezone(
    start_date_time,
    end_date_time,
    coach_facility_timezone,
  )

  const eventCost = total_price_in_cents || price_in_cents
  const totalPriceInDollars = centsToDollarsFormatted(eventCost + additional_fees)

  const isJRL = tags.includes('PGA Jr. League')
  const logoUrl = isJRL ? JRLLogoIcon : FGLogoIcon
  const programImage = isJRL
    ? 'https://images.ctfassets.net/56u5qdsjym8c/1yoePOUAugYt2Za7y7Zmca/394ca263cad1c11e1344efb13ed2e0e3/JRL_programs_bg.png?h=250'
    : 'https://images.ctfassets.net/56u5qdsjym8c/5SWGO8vvLshekEP1mGo826/0345e17afa5d282c0bafb50174b06bf5/FG_programs_bg.png?h=250'

  const [reducePlatformFeeTo3Percent] = useFeatureFlags([flags.FLAG_REDUCE_PLATFORM_FEE_TO_3_PERCENT])
  const fee = reducePlatformFeeTo3Percent ? '3% processing fee*' : '5% processing fee*'

  return (
    <Link href={url}>
      <a style={{ display: 'contents' }} target={openInNewTab ? '_blank' : '_self'}>
        <Card
          className={`${classes.groupEvent}`}
          {...dataAttrs}
        >
          <CardMedia
            className={`${classes.media}`}
            style={{ backgroundImage: `url(${programImage})` }}
          />

          <CardIcon style={{ backgroundImage: `url(${logoUrl})` }} />

          <Box style={{ flexGrow: '1', display: 'flex', flexDirection: 'column' }}>
            <CardContent className={classes.cardContent}>
              <Box>
                {start_date_time && end_date_time
                  ? (
                    <Typography variant="caption" className={classes.caption}>
                      {timePeriod}
                    </Typography>
                  )
                  : (
                    <Typography variant="caption" className={classes.caption}>
                      Program Dates TBD
                    </Typography>
                  )
                }
                <Typography
                  variant="h5"
                  color="primary"
                  className={classes.cardSubtitle}
                >
                  {title}
                </Typography>
              </Box>
              <Box>
                {coach_facility_name != null && (
                  <Box display="flex" mb={1}>
                    <IconFlagFilled />
                    <Typography
                      variant="caption"
                      className={classes.caption}
                      style={{ marginLeft: '12px' }}
                    >
                      {coach_facility_name}
                    </Typography>
                  </Box>
                )}
                {price_in_cents != null && (
                  <Box display="flex">
                    <IconPrice />
                    <Typography
                      variant="caption"
                      className={classes.caption}
                      style={{ marginLeft: '12px' }}
                    >
                      {totalPriceInDollars} / {isJRL ? 'player' : 'team'} + {fee}
                    </Typography>
                  </Box>
                )}
              </Box>
            </CardContent>
          </Box>
          <ButtonCta
            variant="text"
            arrow
            {...(!openInNewTab && { href: url })}
            {...(openInNewTab && { externalLink: url })}
            className={classes.ctaButton}
            dataAttrs={{ ...dataAttrs }}
          >
            View Program
          </ButtonCta>
        </Card>
      </a>
    </Link>
  )
}

ProgramCardAlgolia.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string.isRequired,
    featuredImageUrl: PropTypes.string,
    startDateTime: PropTypes.string,
    endDateTime: PropTypes.string,
    maxAttendance: PropTypes.number,
    slug: PropTypes.string,
    registrationCount: PropTypes.number,
    priceInCents: PropTypes.number,
    sessions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    coachFacility: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      timezone: PropTypes.string,
    }),
  }).isRequired,
  dataAttrs: PropTypes.object,
  openInNewTab: PropTypes.bool,
}

export default ProgramCardAlgolia
